<template>
  <div class="sharedBadge">
    <SharedTransitionWrapper
      :duration="0.2"
      position-type="relative"
      :diagonal="{
        diagonalX: '12px',
        diagonalY: '12px',
        diagonalZ: '-10px',
      }"
      :appear="false"
      :transition-key="showLevelKey"
      transition-name="diagonal"
    >
      <div v-if="showLevel" class="sharedBadge__badge" :class="badgeBlockClasses" :style="badgeBlockStyles">
        <SharedText
          class="sharedBadge__text"
          as="h5"
          :weight="FontWeights.BLACK"
          :text-shadow="disableShadow ? undefined : '0px 1px 1px rgba(0, 0, 0, 0.25)'"
          :color="textColor"
        >
          {{ text }}
        </SharedText>
      </div>
    </SharedTransitionWrapper>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import type { CSSProperties } from 'vue';
import { computed, toRefs } from 'vue';
import type { BadgeCornerOptions, ISharedBadgeProps } from '~/components/SharedBadge/SharedBadge.types';
import { FontWeights } from '~/types/SharedFont.types';
import { Colors } from '~/constants/colors.constants';

const componentName = 'sharedBadge';

const props = withDefaults(defineProps<ISharedBadgeProps>(), {
  // @ts-ignore
  badgeColor: Colors.LVL.LVL_1_4,
  corner: 'top left',
  size: 'medium',
});

const showLevelKey = computed(() => {
  return props.showLevel ? 'show' : 'hide';
});

const { corner, badgeColor, textColor, disableShadow, text, size, positions } = toRefs(props);

const badgeBlockClasses = computed(() => {
  const [vertical, horizontal] = corner.value.split(' ') as BadgeCornerOptions;
  const isTop = vertical === 'top';
  const isLeft = horizontal === 'left';

  const badgeClassName = componentName + '__badge';

  return {
    [`${badgeClassName}--small`]: size.value === 'small',
    [`${badgeClassName}-no-shadow`]: disableShadow.value,
    [`${badgeClassName}-top-left`]: isTop && isLeft,
    [`${badgeClassName}-top-right`]: isTop && !isLeft,
    [`${badgeClassName}-bottom-left`]: !isTop && isLeft,
    [`${badgeClassName}-bottom-right`]: !isTop && !isLeft,
  };
});

const badgeBlockStyles = computed(() => {
  const properties: CSSProperties = {};

  if (badgeColor?.value) {
    const isArray = Array.isArray(badgeColor.value);
    if (isArray && badgeColor.value.length > 1) {
      Object.assign(properties, {
        ...GlobalUtils.CSS.createLinearGradient({
          colorStops: badgeColor.value as string[],
          toDirection: '62.8deg',
        }),
      });
    } else {
      properties['--badge-bg-color'] = (isArray ? badgeColor.value[0] : badgeColor.value) as string;
    }
  }

  if (positions.value) {
    for (const positionKey in positions.value) {
      const typedKey = positionKey as 'left';
      // @ts-ignore
      properties[typedKey] = GlobalUtils.CSS.proceedCssValue(positions.value[positionKey]);
    }
  }

  return properties;
});
</script>

<style src="./SharedBadge.scss" scoped lang="scss"></style>
